import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Container, TabGroup, Text } from 'ui';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { PoolListSearchInput } from './list/search/PoolListSearchInput';
import { usePoolsUIStore } from './store/usePoolsUIStore';
import { usePromotedPools } from 'core';

export function PoolsPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSearchOpen, setIsSearchOpen } = usePoolsUIStore();

  const { promotedPools } = usePromotedPools();
  const hasPromotedPools = promotedPools && promotedPools?.length > 0;

  const searchQuery = searchParams.get('search');
  const isPromotedPage = pathname.includes('/promoted');

  useEffect(() => {
    if (searchQuery && !isSearchOpen) {
      setIsSearchOpen(true);
    }
  }, [setIsSearchOpen, searchQuery, isSearchOpen]);

  useEffect(() => {
    return () => {
      setIsSearchOpen(false);
    };
  }, [setIsSearchOpen]);

  const tabPages = [
    {
      label: intl.formatMessage({ id: 'pools.page.tab.promoted' }),
      path: '/pools/promoted',
    },
    { label: intl.formatMessage({ id: 'pools.page.tab.all' }), path: '/pools' },
  ];

  const handleTabSelect = (i: number) => {
    navigate(tabPages[i].path);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);

    if (isSearchOpen) {
      setSearchParams({});
    } else if (pathname.includes('/new')) {
      navigate('/pools');
    }
  };

  return (
    <Container className="pt-[120px] max-h-[100vh]" column ref={containerRef}>
      <Text type="display" className="text-center mt-4 pb-0" id="pools.page.title" />
      <Text
        type="body-l"
        className="text-center mt-3 mb-10"
        color="secondary"
        id="pools.page.desc"
      />
      {hasPromotedPools && (
        <Container justifyContent="stretch" alignItems="stretch" fullWidth>
          {!isSearchOpen && !searchQuery && (
            <TabGroup
              activeIndex={isPromotedPage ? 0 : 1}
              data={tabPages.map(({ label }) => ({ label }))}
              onSelect={handleTabSelect}
              size="s"
              className="px-0"
            />
          )}
          <PoolListSearchInput
            isSearchOpen={isSearchOpen || !!searchQuery}
            containerRef={containerRef}
            toggleSearch={toggleSearch}
            data-testid="pool-list-search"
          />
        </Container>
      )}
      <Outlet />
    </Container>
  );
}
