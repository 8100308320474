import {
  ClassName,
  PoolWithRatio,
  TestId,
  usePool24hVolume,
  usePoolTvlQuery,
  usePromotedPools,
} from 'core';
import { Link } from 'react-router-dom';
import { ReactComponent as CrownIcon } from 'icons/promoted.svg';
import { Container, FormatCurrency, Grid, RewardsBadge, Text, TokenIcon, formatAmount } from 'ui';

interface PoolListRowProps extends TestId, ClassName {
  pool: PoolWithRatio;
}

export function PoolListRow({ pool }: PoolListRowProps) {
  const {
    poolVolumeHistoryQuery: { data: poolVolume },
  } = usePool24hVolume(pool?.symbols.join('-') || null);
  const poolTvl = usePoolTvlQuery(pool?.id);
  const { poolAprsByLPId } = usePromotedPools();

  const apr = poolAprsByLPId[pool.liquidityTokenId];

  return (
    <Link to={`/pools/${pool.symbols.join('-')}`} className="w-full">
      <Grid
        cols={6}
        gap="m"
        className="bg-widget rounded-xl py-4 px-6 hover:bg-input-hover transition-colors m-2 w-[calc(100%_-_16px)]"
      >
        <Container alignItems="center" className="col-span-3">
          <TokenIcon size="m" isCompact={false} token={[pool.firstAsset, pool.secondAsset]} />
          <Text type="title-3" className="mx-2">
            {pool.firstAsset.symbol} / {pool.secondAsset.symbol}
          </Text>
          {pool.isPromoted && (
            <Container className="rounded bg-accent/[0.1] py-[4px] px-[6px]">
              <CrownIcon className="w-4 h-auto fill-icon-accent" />
            </Container>
          )}
        </Container>
        <Text type="title-4">
          {poolVolume && (
            <>
              <Text color="secondary" className="ml-[2px]">
                $
              </Text>
              <FormatCurrency
                options={{ withoutSymbol: true }}
                value={poolVolume?.toString() || null}
              />
            </>
          )}
        </Text>
        <Text type="title-4">
          <Text color="secondary" className="ml-[2px]">
            $
          </Text>
          <FormatCurrency options={{ withoutSymbol: true }} value={poolTvl || null} />
        </Text>
        <Container>
          <RewardsBadge
            showBackground={false}
            value={formatAmount(apr, { precision: 0, maxChars: 5 })}
            variant="apr"
            data-testid="rewards-badge"
          />
        </Container>
      </Grid>
    </Link>
  );
}
