import { useCallback, useEffect } from 'react';
import { useSDK as useMetamask } from '@metamask/sdk-react';
import { useWalletQuery, useUserStore } from 'core';

export const useMetamaskListener = () => {
  const { provider } = useMetamask();
  const { selectedWallet, setWalletUnlocked } = useUserStore();
  const { selectedWalletQuery } = useWalletQuery(selectedWallet);

  const refetchWallet = selectedWalletQuery.refetch;
  const getUnlockedState = provider?._metamask?.isUnlocked;

  const checkLockedState = useCallback(async () => {
    const isUnlocked = !!(await getUnlockedState?.());
    setWalletUnlocked(isUnlocked);
  }, [getUnlockedState, setWalletUnlocked]);

  useEffect(() => {
    checkLockedState();
  }, [checkLockedState]);

  useEffect(() => {
    provider?.on('accountsChanged', (data) => {
      refetchWallet();
      checkLockedState();
    });

    return () => {
      provider?.removeAllListeners('accountsChanged');
    };
  }, [provider, refetchWallet, checkLockedState]);
};
