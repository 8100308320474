import { Outlet } from 'react-router-dom';
import { AppContainer, Container, LowBalanceModal } from 'ui';
import { GaspNativeTokenWidget, GaspWallet, TxNotificationProvider } from 'modules';
import { Navbar } from '../navbar/Navbar';
import { Welcome } from '../welcome/Welcome';

export function AppLayout() {
  return (
    <div className="prose flex" data-testid="app-layout">
      <Welcome />
      <TxNotificationProvider />
      <Container fullWidth row justifyContent="space-between" alignItems="start">
        <Navbar />
        <AppContainer>
          <Outlet />
        </AppContainer>
        <Container className="absolute right-0 top-0">
          <GaspNativeTokenWidget />
          <GaspWallet />
        </Container>
        <LowBalanceModal />
      </Container>
    </div>
  );
}
