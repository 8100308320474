import { ClassName, useAssets, useDelegators, useSession } from 'core';
import { Container } from 'ui';
import cls from 'classnames';
import { StakingNotification } from 'modules';
import { BN_ZERO } from 'gasp-sdk';

export function PositionListNotifications({ className }: ClassName) {
  const { assetsMap } = useAssets();
  const {
    delegatorStateQuery: { data: delegator },
    delegatorStateRequests: requests,
  } = useDelegators();

  const delegationsMap = new Map(delegator?.delegations?.map((d) => [d.owner.toString(), d]));

  const {
    currentSession: { data: session },
  } = useSession();

  const renderNotifications = () => {
    return Array.from(requests ?? []).map(([, r]) => {
      const delegator = delegationsMap.get(r.collator.toString());
      const asset = assetsMap.get(delegator?.liquidityToken.toString() ?? '');

      return (
        <StakingNotification
          key={r.collator.toString()}
          state={r?.whenExecutable.lte(session ?? BN_ZERO) ? 'ready' : 'scheduled'}
          symbol={asset?.symbol ?? ''}
        />
      );
    });
  };

  return (
    <Container
      column
      fullWidth
      alignItems="start"
      justifyContent="center"
      className={cls('', className)}
    >
      {renderNotifications()}
    </Container>
  );
}
