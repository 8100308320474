import { MetaMaskProvider as Provider } from '@metamask/sdk-react';
import { MetaMaskSDKOptions } from '@metamask/sdk';
import { Children, EnvConfig } from 'core';

const metamaskConfig: MetaMaskSDKOptions = {
  preferDesktop: true,
  extensionOnly: true,
  dappMetadata: {
    name: 'Gasp | The fair DEX',
    url: window.location.host,
  },
};

export const MetaMaskProvider = ({ children }: Children) => {
  if (EnvConfig.isRollupEnv) {
    return <Provider sdkOptions={metamaskConfig}>{children}</Provider>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
