import cls from 'classnames';
import toast from 'react-hot-toast';
import { FormEvent, useEffect } from 'react';
import { EnvConfig, useAccounts, useUserStore } from 'core';
import { Button, Container, IconButton, Modal, Text } from 'ui';
import { useDevToolsStore } from './store/useDevToolsStore';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';

export const DevToolsProvider = () => {
  const { isOpen, userAddressValue, toggleOpen, setUserAddressValue } = useDevToolsStore();
  const { selectedAccount } = useAccounts();
  const areDevToolsEnabled = EnvConfig.DEV_TOOLS === 'true';

  useEffect(() => {
    if (areDevToolsEnabled) {
      setUserAddressValue(selectedAccount?.address || null);
    }
  }, [selectedAccount, setUserAddressValue, areDevToolsEnabled]);

  if (!areDevToolsEnabled) {
    return null;
  }

  const handleUserAddressChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    setUserAddressValue(currentTarget.value);
  };

  const handleSubmit = () => {
    if (userAddressValue) {
      useUserStore.setState({
        selectedAccount: { address: userAddressValue, name: '*Account preview*', type: null },
      });
    }
    toast('State updated!');
    toggleOpen();
  };

  const handleReset = () => {
    useUserStore.setState({ selectedAccount: null, selectedWallet: null });
    toast('State reset!');
    toggleOpen();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={toggleOpen}
        data-testid="dev-tools-modal"
        className="w-[60%] min-w-[600px] max-w-[1000px]"
      >
        <Container fullWidth column className="px-4 py-2">
          <Text type="display" className="mb-10">
            Dev tools
          </Text>
          <Text type="body-m" color="secondary">
            User address
          </Text>
          <input
            type="text"
            data-testid="dev-tools-user-address-input"
            value={userAddressValue || ''}
            autoFocus
            onChange={handleUserAddressChange}
            placeholder="Enter user address"
            className={cls(
              'text-neutral font-body-l py-[12px] px-0 bg-transparent w-full align-bottom mt-4',
              'border-default rounded pl-4 border-1 border-strong border focus:border-highlight !ring-0 !outline-none',
              'peer placeholder:text-secondary relative top-[1px]',
            )}
          />
          <Container className="w-full mt-10">
            <Button
              onClick={handleSubmit}
              className="w-full mr-4"
              data-testid="dev-tools-submit-button"
            >
              Rewrite state
            </Button>
            <Button
              onClick={handleReset}
              variant="outlined"
              className="w-full ml-4"
              data-testid="dev-tools-reset-button"
            >
              Reset state
            </Button>
          </Container>
        </Container>
      </Modal>
      <IconButton
        Icon={SettingsIcon}
        onClick={toggleOpen}
        data-testid="dev-tools-toggle-button"
        className="absolute z-20 right-2 bottom-[70px] bg-widget shadow-xl cursor-pointer"
        size="l"
      />
    </>
  );
};
