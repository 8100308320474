import { useEffect, useState } from 'react';
import { Button, Container, Text } from 'ui';
import { ReactComponent as GaspLogo } from 'icons/gasp-logo.svg';
import { ReactComponent as ExternalLinkIcon } from 'icons/link-ext.svg';
import { useUserStore } from 'core';
import bgImage from '../../assets/images/bg.png';

const steps = [
  <Container>
    <Text>Visit</Text>
    <Text color="highlight" className="ml-1">
      <a
        href="https://holesky-faucet.gasp.xyz/"
        target="_blank"
        rel="noopener noreferrer"
        className="flex"
      >
        GASP Faucet
        <ExternalLinkIcon className="fill-icon-highlight w-[20px] h-auto" />
      </a>
    </Text>
  </Container>,
  <Text>Connect your Metamask wallet.</Text>,
  <Text>Add Holesky test network to your wallet.</Text>,
  <Text>Get GASP test tokens.</Text>,
  <Text>All set! You're ready to explore GASP.</Text>,
];

const renderSteps = () => {
  return steps.map((step, index) => (
    <Container fullWidth key={index} className="flex items-center mt-5">
      <Container
        alignItems="center"
        justifyContent="center"
        className="rounded-full bg-highlight-soft w-5 h-5 p-1 mr-2 border border-highlight/[.08]"
      >
        <Text type="body-s" color="highlight">
          {index + 1}
        </Text>
      </Container>
      {step}
    </Container>
  ));
};

export const Welcome = () => {
  const [isClosed, setIsClosed] = useState(false);
  const { selectedAccount } = useUserStore();

  const handleClose = () => setIsClosed(true);

  useEffect(() => {
    if (selectedAccount && !isClosed) {
      setIsClosed(true);
    }
  }, [selectedAccount, setIsClosed, isClosed]);

  return !isClosed && !selectedAccount ? (
    <Container
      className="w-full h-[100vh] z-40 absolute bg-widget bg-cover"
      column
      alignItems="center"
      justifyContent="center"
    >
      <img
        src={bgImage}
        alt="background"
        className="absolute top-0 bottom-0 z-0 max-w-none w-[100vw] h-[100vh] object-cover"
      />
      <GaspLogo className="h-auto w-[70px] fixed top-6 left-6 z-10" />
      <Container className="bg-widget rounded-xl shadow-xl w-[450px] px-7 py-8 z-10" column>
        <Container alignItems="center" className="px-6">
          <Text weight="semibold" type="display-2">
            Welcome to GASP
          </Text>
          <div className="ml-2 uppercase px-1 border border-strong rounded-lg bg-base">
            <Text type="body-s" color="secondary" weight="semibold" uppercase>
              testnet
            </Text>
          </div>
        </Container>

        <Container column fullWidth className="bg-base p-6 mt-8 rounded-xl">
          <Text weight="semibold" type="title-2">
            Get GASP Test Tokens
          </Text>

          {renderSteps()}
        </Container>

        <Container fullWidth column className="mt-6 px-6">
          <Text type="title-3">We Value Your Feedback!</Text>
          <Text color="secondary" type="body-s" className="mt-2">
            Your insights and experiences are invaluable. If you spot a problem or have a
            suggestion, please tell us! There's a feedback button just for that.
          </Text>
        </Container>

        <Container fullWidth justifyContent="center" className="mt-6">
          <Button variant="primary" size="l" onClick={handleClose}>
            Get Started
          </Button>
        </Container>
      </Container>
    </Container>
  ) : null;
};
