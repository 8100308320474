import { FormattedMessage } from 'react-intl';
import { Button, Container, IconButton, Text } from 'ui';
import { TestId } from 'core';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { ReactComponent as LightImg } from 'assets/images/light.svg';
import sorcererImg from 'assets/images/sorcerer.png';

interface ConnectWalletLayoutProps extends TestId {
  onConnectWallet(): void;
  onClose(): void;
}

export const GaspNotConnectedLayout = ({
  onConnectWallet,
  onClose,
  'data-testid': testId,
}: ConnectWalletLayoutProps) => {
  return (
    <div className="flex flex-col w-full text-center p-4 h-full" data-testid={testId}>
      <Container className="pl-2" justifyContent="space-between" alignItems="center">
        <Container>
          <WalletIcon className="fill-icon-secondary w-[20px] h-[auto]" />
          <Text type="title-4" className="ml-3" color="secondary" id="wallet.notConnected.header" />
        </Container>
        <IconButton Icon={ChevronDown} size="s" onClick={onClose} />
      </Container>
      <Container className="h-full w-full overflow-hidden" justifyContent="center">
        <div className="mt-[90px] w-[250px] self-center flex flex-col justify-items-stretch items-center">
          <Container className="relative" alignItems="center" justifyContent="center">
            <img
              src={sorcererImg}
              alt="sorcerer"
              className="w-[160px] h-auto object-contain inset-0"
            />
            <LightImg
              width="140px"
              height="100%"
              className="absolute inset-0 mx-auto animate-blink"
            />
          </Container>
          <Text type="title-2" className="mt-5" id="wallet.notConnected.title" />
          <Text
            type="body-s"
            color="secondary"
            weight="semibold"
            id="wallet.notConnected.info.text"
            className="mt-3"
          />
          <Button
            size="m"
            data-testid={`${testId}-cta`}
            className="mt-9 w-[178px] hover:w-[200px] transition-all"
            onClick={onConnectWallet}
          >
            <FormattedMessage id={`wallet.notConnected.cta`} />
          </Button>
        </div>
      </Container>
    </div>
  );
};
