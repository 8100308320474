import { ClassName, TestId, TxType } from 'core';
import { Container } from 'ui';
import cls from 'classnames';
import { TxActivityWidgetItem } from './components/TxActivityWidgetItem';

export interface TxActivityWidgetProps extends ClassName, TestId {}

export const TxActivityWidget = ({ 'data-testid': testId, className }: TxActivityWidgetProps) => {
  return null;
  return (
    <div className={cls('p-4 w-full', className)} data-testid={testId}>
      <Container fullWidth column className="p-2 bg-input rounded-lg">
        <TxActivityWidgetItem
          type={TxType.RollupDeposit}
          amount={'2'}
          symbol={'ETH'}
          timestamp={'1723472577'}
        />
        <TxActivityWidgetItem
          type={TxType.RollupWithdrawal}
          amount={'2'}
          symbol={'ETH'}
          timestamp={'1723472277'}
        />
      </Container>
    </div>
  );
};
