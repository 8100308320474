import cls from 'classnames';
import React, { ReactNode, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { Children, ClassName, TestId } from '../../types';

export type TextElementType = HTMLParagraphElement | HTMLSpanElement | HTMLHeadingElement;

type TextWeight = 'normal' | 'light' | 'semibold' | 'bold' | 'black';
type TextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
type TextType =
  | 'display'
  | 'display-2'
  | 'title-1'
  | 'title-2'
  | 'title-3'
  | 'title-4'
  | 'title-5'
  | 'section'
  | 'label'
  | 'value'
  | 'body-l'
  | 'body-m'
  | 'body-s'
  | 'tiny'
  | 'body-xs'
  | 'btn-l'
  | 'btn-m'
  | 'link';
type TextColor =
  | 'primary'
  | 'accent'
  | 'accent-hover'
  | 'alert'
  | 'warning'
  | 'inverted'
  | 'accent-inverted-surface'
  | 'secondary'
  | 'blocked'
  | 'highlight'
  | 'btn-primary'
  | 'btn-secondary'
  | 'btn-premium'
  | 'btn-disabled';

interface TextWithChildren extends Children {
  id?: never;
  values?: never;
}

interface TextWithTranslation {
  children?: never;
  values?: Record<string, string | ReactNode>;
  id: string;
}

type TextDisplayType = TextWithChildren | TextWithTranslation;

interface BaseTextProps extends ClassName, TestId {
  element?: TextElement;
  type?: TextType;
  color?: TextColor;
  weight?: TextWeight;
  uppercase?: boolean;
}

type TextProps = BaseTextProps & TextDisplayType;

const TYPES: Record<TextType, string> = {
  display: 'font-display',
  'display-2': 'font-display-2',
  'title-1': 'font-title-1',
  'title-2': 'font-title-2',
  'title-3': 'font-title-3',
  'title-4': 'font-title-4',
  'title-5': 'font-title-5',
  section: 'font-section',
  label: 'font-label',
  value: 'font-value',
  'body-l': 'font-body-l',
  'body-m': 'font-body-m',
  'body-s': 'font-body-s',
  'body-xs': 'font-body-xs',
  'btn-l': 'font-btn-l',
  'btn-m': 'font-btn-m',
  link: 'font-link',
  tiny: 'font-tiny',
};

const COLORS: Record<TextColor, string> = {
  primary: 'text-neutral',
  accent: 'text-accent',
  alert: 'text-alert',
  warning: 'text-warning',
  'accent-hover': 'text-highlight-hover',
  inverted: 'text-inverted',
  'accent-inverted-surface': 'text-highlight-inverted-surface',
  secondary: 'text-secondary',
  blocked: 'text-blocked',
  highlight: 'text-highlight',
  'btn-primary': 'text-btn-primary',
  'btn-secondary': 'text-btn-secondary',
  'btn-premium': 'text-btn-premium',
  'btn-disabled': 'text-btn-disabled',
};

const WEIGHTS = {
  normal: 'font-normal',
  light: 'font-light',
  semibold: 'font-semibold',
  bold: 'font-bold',
  black: 'font-black',
};

export const Text = forwardRef<TextElementType, TextProps>(
  (
    {
      element = 'span',
      type = 'body-m',
      color,
      children,
      id,
      className,
      weight,
      'data-testid': testId,
      values,
      uppercase,
    }: TextProps,
    ref,
  ) => {
    const intl = useIntl();

    const component = React.createElement(
      element,
      {
        ref,
        className: cls(
          'transition-all',
          TYPES[type],
          color && COLORS[color],
          weight && WEIGHTS[weight],
          uppercase && 'uppercase',
          className,
        ),
        'data-testid': testId,
      },
      id ? intl.formatMessage({ id }, values) : children,
    );

    return component;
  },
);
