import { useLocation } from 'react-router';

import { Container, NavItem } from 'ui';

import { ReactComponent as swapIcon } from 'icons/swap.svg';
import { ReactComponent as poolsIcon } from 'icons/pools.svg';
import { ReactComponent as positionsIcon } from 'icons/positions.svg';
import { ReactComponent as GaspLogo } from 'icons/gasp-logo.svg';
import { useIntl } from 'react-intl';
import { useDelegators, useSession } from 'core';
import { BN_ZERO } from 'gasp-sdk';

export const Navbar = () => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const { delegatorStateRequests: requests } = useDelegators();

  const {
    currentSession: { data: session },
  } = useSession();

  const hasReadyStakeChange = Array.from(requests ?? []).some(([, r]) =>
    r?.whenExecutable.lte(session ?? BN_ZERO),
  );

  const firstRoute = pathname.split('/')[1];

  return (
    <Container
      column
      justifyContent="space-between"
      alignItems="center"
      className="h-screen w-[264px] min-w-[264px] z-20"
    >
      <Container column alignItems="start" fullWidth className="px-6 h-full">
        <Container alignItems="center" className="mt-6">
          <GaspLogo className="h-auto w-[70px]" />
        </Container>
        <Container className="h-full" alignItems="center">
          <Container column fullWidth>
            <NavItem
              isActive={pathname === '/'}
              path="/"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.swap' })}
              Icon={swapIcon}
              data-testid="nav-swap"
            />
            <NavItem
              isActive={firstRoute === 'pools'}
              path="/pools/promoted"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.pools' })}
              Icon={poolsIcon}
              data-testid="nav-pools"
            />
            {/* <NavItem
              isActive={firstRoute === 'tokens'}
              path="/tokens"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.tokens' })}
              Icon={tokensIcon}
              data-testid="nav-tokens"
            /> */}
            {/*<NavItem
              isActive={firstRoute === 'staking'}
              path="/staking"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.staking' })}
              Icon={stakingIcon}
              data-testid="nav-staking"
            />*/}
            <NavItem
              isActive={firstRoute === 'positions'}
              hasNotification={hasReadyStakeChange}
              path="/positions"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.positions' })}
              Icon={positionsIcon}
              data-testid="nav-positions"
            />
          </Container>
        </Container>
      </Container>
    </Container>
  );
};
